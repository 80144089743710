@import "responsive-container.css";
@import "react-select";

//i {
//  z-index: 1
//}

.a {
  cursor: pointer !important;
}

//p {
//  text-align: justify;
//}

.text-justify {
  text-align: justify;
}

.logo-name {
  color: #154B83;
  text-decoration: #860408 underline;
  font-weight: 700;
}

.dark-mode .logo-name {
  color: #FFFFFF;
}


ol {
  /* убираем стандартную нумерацию */
  list-style: none;
  /* Идентифицируем счетчик и даем ему имя li. Значение счетчика не указано - по умолчанию оно равно 0 */
  counter-reset: li;

  padding-left: 1rem;

  li:before {
    /* Определяем элемент, который будет нумероваться — li. Псевдоэлемент before указывает, что содержимое, вставляемое при помощи свойства content, будет располагаться перед пунктами списка. Здесь же устанавливается значение приращения счетчика (по умолчанию равно 1). */
    counter-increment: li;
    /* С помощью свойства content выводится номер пункта списка. counters() означает, что генерируемый текст представляет собой значения всех счетчиков с таким именем. Точка в кавычках добавляет разделяющую точку между цифрами, а точка с пробелом добавляется перед содержимым каждого пункта списка */
    content: counters(li, ".") ". ";
  }


}





.range-ship::-webkit-slider-thumb {
  height: 40px;
  width: 40px;
  margin-top: -10px;
  border-radius: 0;
  transform: scaleX(-1);
}
html.dark-mode {
  .range-ship::-webkit-slider-thumb {
    background: url('logo-ship-white.png') no-repeat;
    background-size: contain;
  }
  .range-ship:focus::-webkit-slider-thumb {
    background: url('logo-ship-white.png') no-repeat;
    background-size: contain;
  }
}
html:not(.dark-mode) {
  .range-ship::-webkit-slider-thumb {
    background: url('logo-ship-dark.png') no-repeat;
    background-size: contain;
  }
  .range-ship:focus::-webkit-slider-thumb {
    background: url('logo-ship-dark.png') no-repeat;
    background-size: contain;
  }
}


