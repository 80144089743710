.pulseBtn {
  /*display: box;*/
  position: relative;
  width: 100px;
  height: 100px;
  background: linear-gradient(to bottom, #154b83 100%, white 0%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
}


.pulseBtn::before {
  display: block;
  position: absolute;
  /*z-index: -1;*/
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: black;
  opacity: 0;
  content: '';
  animation: pulse 1s infinite;
}

.pulseBtn:hover::before {
  animation: none;
  opacity: .4;
  transform: scale(1.3);
}
.pulseBtn.is-clicked {
  background: linear-gradient(to bottom, gray 0%, dimgray 100%);
}
.pulseBtn.is-clicked:before {
  animation: blastOut 1s;
}

.pulseBtn img {
  width: 60px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: .4;
  }
  to {
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes blastOut {
  from {
    transform: scale(0.9);
    opacity: .4;
  }
  to {
    transform: scale(10);
    opacity: 0;
  }
}