.item-responsive {
    position: relative; /* относительное позиционирование */
}

.item-responsive:before {
    display: block; /* отображать элемент как блок */
    content: ""; /* содержимое псевдоэлемента */
    width: 100%; /* ширина элемента */
}

.item-responsive > .content {
    position: absolute; /* абсолютное положение элемент */
    /* положение элемента */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
}


.item-16by9 {
    padding-top: 56.25%; /* (9:16)*100% */
}

.item-4by3 {
    padding-top: 75%; /* (3:4)*100% */
}

.item-2by1 {
    padding-top: 50%; /* (1:2)*100% */
}

.item-1by1 {
    padding-top: 100%; /* (1:1)*100% */
}