/*.react-select-filter__control {*/
/*    border-radius: 0 0.75rem 0.75rem 0 !important;*/
/*    border-color: #dfdfeb !important;*/
/*    min-height: 44px !important;*/
/*}*/


.react-select__control {
    border-radius: 16px !important;
    min-height: 47px !important;
    padding-left: 5px !important;
    border-color: #858c97 !important;
}
.react-select__placeholder {
    font-size: 14px;
    color: #8c8da0;
}


.dark-mode {
    .react-select__control {
        background-color: #121519;
        box-shadow: none;
    }
    //.react-select__control--is-focused {
    //    border-color: red;
    //}
    //.react-select__control--menu-is-open {
    //    border-color: darkgoldenrod;
    //}
    //.react-select__control--is-selected {
    //    border-color: blue;
    //}
    //.react-select__control--is-active {
    //    border-color: yellow;
    //}
    //.react-select__control:hover {
    //    border-color: yellow;
    //}



    .react-select__menu {
        background-color: #121519;
    }
    .react-select__option--is-focused {
        background-color: #2d343d;
    }
    .react-select__option--is-selected {
        background-color: #47505b;
    }
    .react-select__single-value {
        color: gray;
    }
}